export const clearMacadams = () => ({
  type: 'CLEAR_MACADAMS'
});

export const removeMacadam = macadam => ({
  type: 'REMOVE_MACADAM',
  macadam
});

export const addMacadam = macadam => ({
  type: 'ADD_MACADAM',
  macadam
});

export const addMacadams = macadams => ({
  type: 'ADD_MACADAMS',
  macadams
});

export const changeMacadam = macadam => ({
  type: 'CHANGE_MACADAM',
  macadam
});

